<template>
  <div class="plp">
    <div class="container">
      <AppBreadcrumbs />

      <div class="plp__container">
        <div class="plp__description">
          <!-- <AppLink
            v-if="previousPage"
            :to="`/${previousPage.url}`"
            class="back plp-banner__back"
          >
            <IconArrow class="icon icon--arrow" />
            {{ previousPage.label }}
          </AppLink> -->

          <h1 class="plp__heading heading heading--1">
            <span v-if="search">{{ $t('Search results for') }}: '{{ search }}'</span>
            <span v-else>{{ pageTitle }}</span>
          </h1>

          <UiReadMore
            v-if="plpPage?.PLPData?.Description"
            class="plp__description--text"
          >
            <StrapiBlocksText :nodes="plpPage.PLPData?.Description" />
          </UiReadMore>
        </div>

        <CatalogBanners
          v-if="plpBanners?.length"
          class="plp__banners"
          :banners="plpBanners"
        />

        <div class="plp__quick-links">
          <div class="plp__quick-links__heading">
            <span class="heading heading--6">{{ $t('All') }} {{ pageTitle }}</span>
            <span class="plp__product-count">{{ totalCount }} artikelen</span>
          </div>

          <CatalogQuickLinks
            v-if="plpQuicklinks?.length"
            :links="plpQuicklinks"
          />
        </div>

        <PaginatorToolbar
          class="plp__paginator plp__paginator--top"
          :sort="sort"
          :sort-options="sortOptions"
          v-on="paginatorMethods"
        />

        <div class="plp__sidebar">
          <!-- <FilterCategories class="plp__categories" :url-data="urlData" /> -->
          <FilterList
            class="plp__filter-list"
          />
        </div>

        <div
          id="product-grid"
          class="plp__products products"
          :class="`products--${view}`"
        >
          <ProductTile
            v-for="(product, index) in products"
            :key="index"
            :product="product"
            :lazy="index > 2 || !plpBanners?.length"
            list="ProductListerPage"
          />

          <AppUsps
            v-if="plpUsps?.length && page === 1 && products.length >= 12"
            class="plp__usps"
            :usps="plpUsps"
          />

          <ProductBanner
            v-if="plpGridBanner1 && page === 1 && products.length >= 7"
            class="plp__banner plp__banner--1"
            :banner="plpGridBanner1"
          />

          <ProductBanner
            v-if="plpGridBanner2 && page === 1 && products.length >= 18"
            class="plp__banner plp__banner--2"
            :banner="plpGridBanner2"
          />
        </div>

        <PaginatorToolbar
          class="plp__paginator plp__paginator--bottom"
          :current-page="page"
          :total-pages="totalPages"
          v-on="paginatorMethods"
        />
      </div>
    </div>

    <StrapiSlices
      v-if="plpPage?.Slices?.length"
      :slices="plpPage.Slices"
    />

    <div class="container">
      <ThirdpartySqueezelyProductSlider id="spotler-activate-plp-slider-1" />
      <ThirdpartySqueezelyProductSlider id="spotler-activate-plp-slider-2" />
    </div>

    <UiScrollToTop class="plp__scroll-to-top" />

    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
    <!--  -->
  </div>
</template>

<script lang="ts" setup>
import type { DataLayerItem } from '~layers/app/composables/useDataLayer'
import layoutQuery from '~layers/app/graphql/layout.gql'

const { public: config } = useRuntimeConfig()
const { data: layoutData } = await useAsyncQuery(layoutQuery, { locale: config.strapiLocale, status: 'PUBLISHED' })

const { setBreadcrumbs } = useBreadcrumbs()
const { search } = useCatalogSearch()
const route = useRoute()
const { t } = useI18n({ useScope: 'global' })

const meta = inject('meta', ref(search.value ? { strapi_id: layoutData.value?.search?.[0]?.documentId, filters: [{ key: 'stock', values: ['in-stock'] }] } : undefined))

setBreadcrumbs({
  label: meta?.value?.label || 'Search',
  parents: meta?.value?.parents || [],
})

const {
  // Request data
  // error,
  // data,
  // loading,

  // Product data
  products,
  fetchProductData,
  strapiData,
  getStrapiData,
} = await useCatalog(meta)

const plpPage = computed(() => {
  return strapiData.value?.plpPage
})

const plpUsps = computed(() => {
  return strapiData.value?.plpUsps?.[0].Usp
})

const plpTooltips = computed(() => {
  return strapiData.value?.tooltips
})

provide('tooltips', plpTooltips)

const plpBanners = computed(() => {
  return plpPage.value?.PLPData?.Banners
})

const plpQuicklinks = computed(() => {
  return plpPage.value?.PLPData?.Quicklinks?.Icons
})

const plpGridBanner1 = computed(() => {
  return plpPage.value?.PLPData?.GridBanner1
})

const plpGridBanner2 = computed(() => {
  return plpPage.value?.PLPData?.GridBanner2
})

const {
  page,
  totalPages,
  sort,
  sortOptions,
  // itemsPerPage,
  // itemsPerPageOptions,
  view,
  totalCount,
  paginatorMethods,
} = useCatalogPaginator()

//  const {
// Options Level
// optionToggle,
// filterRangeResetMin,
// filterRangeResetMax,

// Single Filter Level
// filterApply,
// filterReset,

// All Filters Level
// rawFilters,
// filters,
// filtersApplyAll,
// filtersResetAll,
// filtersRemoveAll
// filtersFromRoute,
// activeFilters,
// activeFiltersCount
// expectedTotal,
// getExpectedTotal
// } = useCatalogFilters(meta)

await Promise.all([
  fetchProductData(),
  meta?.value?.strapi_id ? getStrapiData() : undefined,
])

const pageTitle = computed(() => {
  const label = meta.value?.label

  if (!label) return ''

  return label
    .split(' ')
    .map(word => (word === word.toUpperCase() ? word : useTitle(word)))
    .join(' ')
})

const metaTitle = computed(
  () => plpPage.value?.MetaData?.MetaTitle || pageTitle.value,
)

const metaDescription = computed(
  () => plpPage.value?.MetaData?.MetaDescription || pageTitle.value + ' | ' + t('nuxtSiteConfig.description'),
)

// function resetAndApplyAll () {
//   filtersRemoveAll()
//   filtersApplyAll()
// }

// function toggleActive (filterKey: string, optionKey: string) {
//   optionToggle(filterKey, optionKey)
//   filterApply(filterKey)
// }

// function resetMin (filterKey: string) {
//   filterRangeResetMin(filterKey)
//   filterApply(filterKey)
// }

// function resetMax (filterKey: string) {
//   filterRangeResetMax(filterKey)
//   filterApply(filterKey)
// }

const { pageView, viewItemList } = useDataLayer()
pageView('overview', pageTitle.value)

const dataLayerOverrides = computed<Partial<DataLayerItem>>(() => ({
  item_list_name: 'plp',
  item_list_id: route.fullPath,
}))

const category = computed<Partial<MagentoCategory>>(() => ({
  id: meta.value?.id?.split('-')?.[1],
  name: meta.value?.label,
}))

watch(products, () => {
  viewItemList(products.value, dataLayerOverrides.value, category.value)
}, { immediate: true })

const metaRobots = computed(() => {
  const { p, filter, color, ...restOfQuery } = route.query
  if (Object.keys(restOfQuery).length > 0) {
    return 'noindex, follow'
  }
  if (plpPage.value?.MetaData?.MetaRobots) {
    return plpPage.value.MetaData?.MetaRobots?.replace('_', ', ')
  }
  return 'index, follow'
})

useHead({
  title: metaTitle,
  meta: [
    { hid: 'description', name: 'description', content: metaDescription },
    { hid: 'robots', name: 'robots', content: metaRobots },
  ],
})

const canonicalUrl = computed(() => {
  if (metaRobots.value.startsWith('index')) {
    return route.fullPath
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { filter, color, ...restOfQuery } = route.query
  const searchParams = new URLSearchParams({
    ...(color ? { filter, color } : {}),
  })
  return route.path + (searchParams.size > 0 ? '?' + searchParams.toString() : '')
})

const { canonical } = useSeo()
canonical(canonicalUrl)
</script>

<style src="~layers/app/pages/~plp.css" />
